define(['app', '$console', 'componentHelper'], (app, $console, componentHelper) => {

  const foundationFinderProductMatcherQuestion = () => {
    const component = {};
    const BRAND = "brand";
    const PRODUCT = "product";
    const SHADE = "shade";
    const DATA_CHOICES = "data-choices";
    const DATA_MASTER_ID = "data-masterId";
    const DATA_SHOW = "data-show";
    const DATA_SHOW_FF_TOOL = "data-show-ff-tool";
    const trackingEventCategory = "Find Your Shade";

    component.config = {
      selectors: {
        sourceProductInput: '#sourceProduct-sourceProduct',
        dropdownInput: '.dropdownInput_input',
        simpleDropdownInput: '.simpleDropdownInput',
        matchButton: '.foundationFinderProductMatcher_matcher_cta',
        matcher: '.foundationFinderProductMatcher_matcher',
        brand: '.foundationFinderProductMatcher_matcher_dropdown.brand',
        product: '.foundationFinderProductMatcher_matcher_dropdown.product',
        shade: '.foundationFinderProductMatcher_matcher_dropdown.shade',
        resultContainer: '.foundationFinderProductMatcher_resultContainer',
        editResultsButton: '.foundationFinderProductMatcherResult_sourceProduct_cta',
        modalDialog: '.modal_dialog',
        dialogContent: '.modal_dialog_content.foundationFinderProductMatcherContainer',
        wishlistButton: '.productAddToWishlist_button_default',
        modalTriggerButton: '.foundationFinderProductMatcherTrigger',
        isTargetNoCloseButton: '.foundationFinderProductMatcherError_isTargetNotClose_cta',
        isTargetNoCloseEditProductButton: '.foundationFinderProductMatcherError_isTargetNotClose_editProduct_cta',
        invalidTargetOrSourceButton: '.foundationFinderProductMatcherError_isInvalidTargetOrSource_cta',
        fallbackErrorContainer: '.foundationFinderProductMatcherError_isInvalidTargetOrSource',
        matcherTitle: '.foundationFinderProductMatcher_matcher_title',
        resultsTitle: '.foundationFinderProductMatcherResult_title',
        errorTitle: '.foundationFinderProductMatcherError_title'
      },
      attributes: {
        productId: 'data-product-id'
      },
      channels: {
        inputSelected: 'dropdownInput/inputSelected',
        refreshChoices: 'dropdownInput/refreshChoices',
        disableNext: 'quiz/nextDisabled'
      }
    };

    component.init = (element, userDriven) => {
      component.element = element;
      component.brand = component.element.querySelector(component.config.selectors.brand);
      component.product = component.element.querySelector(component.config.selectors.product);
      component.shade = component.element.querySelector(component.config.selectors.shade);
      component.sourceProductInput = document.querySelector(component.config.selectors.sourceProductInput);
      component.brandSimpleDropdownInput = component.brand.querySelector(component.config.selectors.simpleDropdownInput);
      component.productSimpleDropdownInput = component.product.querySelector(component.config.selectors.simpleDropdownInput);
      component.shadeSimpleDropdownInput = component.shade.querySelector(component.config.selectors.simpleDropdownInput);
      component.brandDropdownInput = component.brand.querySelector(component.config.selectors.dropdownInput);
      component.productDropdownInput = component.product.querySelector(component.config.selectors.dropdownInput);
      component.shadeDropdownInput = component.shade.querySelector(component.config.selectors.dropdownInput);
      component.matcher = component.element.querySelector(component.config.selectors.matcher);
      component.matcherTitle = component.element.querySelector(component.config.selectors.matcherTitle);
      component.resultContainer = component.element.querySelector(component.config.selectors.resultContainer);
      component.fallbackErrorContainer = component.element.querySelector(component.config.selectors.fallbackErrorContainer);
      component.masterId = component.element.getAttribute(DATA_MASTER_ID);
      component.productDropdownInput.disabled = true;
      component.shadeDropdownInput.disabled = true;
      component.productMap = new Map();
      component.shadeMap = new Map();
      component.brandsList = [];
      component.showFFTool = component.element.getAttribute(DATA_SHOW_FF_TOOL) === 'true';

      if (!userDriven) {
        component.subscribe();
        component.getValues();
      }
    };


    component.getValues = () => {
      app.ajax.get({
        url: '/products.foundationMatcher',
        success: component.successHandler,
        error: component.errorHandler
      });
    };


    component.subscribe = () => {
      app.subscribe(component.config.channels.inputSelected, component.updateSelectedDropdown)
    };

    component.getValues = () => {
      app.ajax.get({
        url: '/products.foundationMatcher',
        success: component.successHandler,
        error: component.errorHandler
      });
    };

    component.successHandler = (res) => {
      component.JSONdata = JSON.parse(res);

      for (let [brand, brandProducts] of Object.entries(component.JSONdata.brands)) {
        component.brandsList.push({value: brand, display: brand.trim()});
        let productList = [];

        brandProducts.forEach(product => {
          let shadeList = [];
          productList.push({value: product.productId, display: product.displayName.trim()});
          product.shades.forEach(shade =>
            shadeList.push({value: shade.productId, display: shade.displayName.trim()})
          );
          component.shadeMap.set(product.productId, shadeList)
        });
        component.productMap.set(brand, productList)
      }

      component.updateDropdownData(
        component.brandSimpleDropdownInput,
        component.brandsList,
        BRAND);
    };

    component.errorHandler = () => {
      $console.error("Error: Failed the get brand products from foundation finder service");
    };

    component.updateSelectedDropdown = (selected) => {
      if(selected && selected.name === BRAND) {
        component.brandDropdownChange(selected.value);
      } else if (selected && selected.name === PRODUCT) {
        component.productDropdownChange(selected.value);
      } else if (selected && selected.name === SHADE) {
        component.shadeDropdownChange(selected.value);
      }
    };

    component.disableNext = (disabled) => {
      app.publish(component.config.channels.disableNext, disabled);
    }

    component.brandDropdownChange = (selectedValue) => {
      component.productDropdownInput.value = null;
      component.shadeDropdownInput.value = null;
      component.shadeDropdownInput.disabled = true;
      component.disableNext(true);

      if (!selectedValue) {
        component.productDropdownInput.disabled = true;
      } else {
        component.productDropdownInput.disabled = false;

        component.updateDropdownData(
          component.productSimpleDropdownInput,
          component.productMap.get(selectedValue),
          PRODUCT);
      }
    };

    component.productDropdownChange = (selectedValue) => {
      component.shadeDropdownInput.value = null;
      component.disableNext(true);

      if (!selectedValue) {
        component.shadeDropdownInput.disabled = true;
      } else {
        component.shadeDropdownInput.disabled = false;

        component.updateDropdownData(
          component.shadeSimpleDropdownInput,
          component.shadeMap.get(parseInt(selectedValue)),
          SHADE);
      }
    };

    component.shadeDropdownChange = (selectedValue) => {
      if (selectedValue) {
        component.disableNext(false);
        component.currentShadeSelected = selectedValue;
        component.sourceProductInput.value = selectedValue;
        component.sourceProductInput.checked = true;
      } else {
        component.disableNext(true);
      }
    };

    component.updateDropdownData = (dropdown, data, inputName) => {
      if (data) {
        dropdown.setAttribute(DATA_CHOICES, JSON.stringify(data));
        app.publish(component.config.channels.refreshChoices, inputName);
      }
    };

    return component;
  };

  return foundationFinderProductMatcherQuestion;
});
